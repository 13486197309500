.App {
  padding: 20px;
}

.Row {
  padding: 20px;
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.RowTitle {
  padding-right: 16px;
  word-wrap: break-word;

  @media (min-width: 768px) {
    min-width: 140px;
    width: 25%;
  }
}

.BarWrapper {
  margin-left: 20px;
  flex-grow: 1;
  fill: white;

  @media (max-width: 767px) {
    width: 100%;
  }
}